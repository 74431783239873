<template>
  <div>
    <Loader :loading="loading" />
    <div class="d-flex justify-space-between">
      <div style="width: 150px;">
        <v-select
          v-model="perPage"
          label="Total visible"
          :items="listPerPage"
          @change="resetCurrentPage"
          dense
        />
      </div>
      <div>
        <v-btn
          @click="getPO"
          color="warning"
          small
          text
        >
          <v-icon left>mdi-refresh</v-icon>
          Refrescar
        </v-btn>
        <v-btn
          @click="createOrder('po')"
          color="primary"
          small
          text
        >
          <v-icon left>mdi-plus</v-icon>
          Pedido
        </v-btn>
        <v-btn
          @click="createOrder('pop')"
          color="primary"
          small
          text
        >
          <v-icon left>mdi-plus</v-icon>
          Pedido Proveedor
        </v-btn>
      </div>
    </div>
    <v-data-table
      :headers="headers"
      :items="orders"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      fixed-header
    >
      <!-- <template v-slot:item.actions="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              link
              @click="openDetails(item)"
            >
              <v-icon left>mdi-menu</v-icon>
              <small>Detalles</small>
            </v-list-item>
          </v-list>
        </v-menu>
      </template> -->
      <template v-slot:item.origin="{ item }">
        <Origin
          :detail="item.origin_warehouse_id === null ? item.buy_entity : item.origin"
          :type-detail="item.destiny_warehouse_id === null ? 'supplier' : 'warehouse'"
        />
      </template>
      <template v-slot:item.destiny="{ item }">
        <div>
          <div>
            <small class="text--primary">
              <v-icon left x-small> {{ icons[item.destiny.type] }} </v-icon>
              {{ item.destiny.name }}
            </small>
          </div>
          <div>
            <small class="text--primary"> {{ item.destiny.description }} </small>
          </div>
        </div>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip
          :color="colors[item.status.name]"
          x-small
          label
          outlined
        >
          {{ translators[item.status.name] }}
        </v-chip>
      </template>
      <template v-slot:item.items="{ item }">
        <TTruncate
          v-for="sku in item.items"
          :key="`sku-item-${sku.id}`"
          :text="`${sku.sku.sku} x ${sku.quantity}`"
          :title="`${sku.sku.sku} - ${sku.sku.product.name}`"
          :width="100"
          small
        />
        <small class="d-flex justify-center">{{ item.items.length }} items</small>
      </template>
      <template v-slot:item.total="{ item }">
        <b class="success--text"> Bs. {{ item.total }} </b>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-pagination
        v-model="currentPage"
        :disabled="loading"
        :length="lastPage"
        :total-visible="8"
      />
    </div>
  </div>
</template>

<script>
// services
import OrderServices from '@/store/services/warehouse/OrderServices';
// components
import TTruncate from '@/components/commons/tiny/TTruncate.vue';
import Origin from '../order/OrderDO';
// helpers
import { mapGetters } from 'vuex';
import ListMixin from '@/mixins/commons/ListMixin';
import { WAREHOUSE_ICON, STATUS_COLOR, STATUS_TRANSLATOR } from '@/constants';
export default {
  mixins: [ListMixin],
  components: {
    TTruncate,
    Origin,
  },
  data() {
    return {
      loading: false,
      orders: [],

      icons: WAREHOUSE_ICON,
      colors: STATUS_COLOR,
      translators: STATUS_TRANSLATOR,
    };
  },
  created() {
    this.getPO();
  },
  methods: {
    openDetails(item) {
      this.showDetails = true;
      this.currentSku = item;
    },
    async getPO() {
      try {
        const params = {
          search_type: ['PURCHASE', 'BUY'],
          page: this.currentPage,
          order_by: 'id',
          order_direction: 'DESC',
          per_page: typeof this.perPage === 'string' ? this.total : this.perPage,
          include: 'items.sku.product,origin,destiny,status,buyEntity.contact,billEntity.contact',
        };
        this.loading = true;
        const response = await OrderServices.index(this.user.archon.id, params);
        this.orders = response.data.data;
        this.currentPage = response.data.current_page;
        this.lastPage = response.data.last_page;
        this.total = response.data.total;
        
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    createOrder() {
      this.$router.push({
        name: 'purchaseOrderCreate',
      });
    },
    // list
    resetCurrentPage() {
      if (this.currentPage === 1) this.getPO();
      else this.currentPage = 1;
    },
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    headers() {
      return [
        {
          text: '',
          sortable: false,
          value: 'actions',
        },
        {
          text: 'Pedido #',
          sortable: false,
          value: 'order_num',
        },
        { text: 'Instrucciones', value: 'instructions', sortable: false, align: 'center' },
        { text: 'Origen', value: 'origin', sortable: false },
        { text: 'Destino', value: 'destiny', sortable: false },
        { text: 'Estado', value: 'status', sortable: false },
        { text: 'Items', value: 'items', sortable: false },
      ];
    },
  },
  watch: {
    currentPage() {
      this.getPO();
    },
  },
}
</script>
<template>
  <div v-if="typeDetail === 'contact'">
    <div>
      <small>
        <v-icon left small>mdi-card-account-details-outline</v-icon>
        {{ detail.contactable.nit }}
      </small>
    </div>
    <div>
      <small>
        <v-icon left small>mdi-account-outline</v-icon>
        {{ detail.name }}
      </small>
    </div>
  </div>
  <div v-else-if="typeDetail === 'supplier'">
    <div>
      <small>
        <v-icon left small>mdi-card-account-details-outline</v-icon>
        {{ detail.nit }}
      </small>
    </div>
    <div>
      <small>
        <v-icon left small>mdi-account-outline</v-icon>
        {{ detail.name }}
      </small>
    </div>
    <div>
      <small>
        <v-icon left small>mdi-alarm-check</v-icon> 
        {{ detail.supplier.processing_time }} {{ detail.supplier.period }}
      </small>
    </div>
  </div>
  <div v-else>
    <div>
      <small class="text--primary">
        <v-icon left x-small color="success"> {{ icons[detail.type] }} </v-icon>
        {{ detail.name }}
      </small>
    </div>
    <div>
      <small class="text--secondary">
        <v-icon left x-small>mdi-text</v-icon>
        {{ detail.description }}
      </small>
    </div>
  </div>
</template>

<script>
import { WAREHOUSE_ICON } from '@/constants';
export default {
  data() {
    return {
      icons: WAREHOUSE_ICON,
    };
  },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
    typeDetail: {
      type: String,
      default: 'warehouse',
    },
  },
};
</script>
<template>
  <div
    :id="idDinamic"
    :title="title"
  >
    {{ text }}
  </div>
</template>

<script>
import { uuid } from '@/constants/helpers';
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 200,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      idDinamic: uuid(),
    };
  },
  mounted() {
    this.initComponent();
  },
  methods: {
    initComponent() {
      const component = document.getElementById(this.idDinamic);
      component.style.textOverflow = 'ellipsis';
      component.style.width = `${this.width}px`;
      component.style.overflow = 'hidden';
      component.style.whiteSpace = 'nowrap';
      component.style.color = '#616161';
      if (this.small) {
        component.style.fontSize = '10px';
      }
    },
  },
};
</script>
